$ = require "jquery"

class LocalAjaxCache
  constructor: (@key, @url, @events)->
    @store = window.localStorage

  get: =>
    if @store? and @store.getItem(@key)
      @events.emit "local_ajax_cache:#{@key}", JSON.parse(@store.getItem(@key))
    @refresh()

  refresh: =>
    me = this
    $.ajax(@url, type: "GET").done (data)->
      if data and data.data and not data.errors
        me.events.emit "local_ajax_cache:#{me.key}", data.data
        me.store.setItem(me.key, JSON.stringify(data.data)) if me.store?

  set: (data)=>
    if @store? then @store.setItem(@key, JSON.stringify(data))
    @events.emit "local_ajax_cache:#{@key}", data

module.exports = LocalAjaxCache
