$ = require "jquery"

# Emulate bootstrap toggle method

class Collapse
	constructor: (@$elm, @events)->
		$('.coffee-collapse').each ->
			$(this).click ->
				id = $(this).attr('href')
				if $(id).hasClass('in')
					$(id).slideUp(200).removeClass('in')
				else
					$(id).slideDown(200).addClass('in')
				return false

module.exports = Collapse
