$ = require "jquery"

class FreeShippingMasthead
  constructor: ->
    $(".free-shipping").on "click", ->
        $(this).find("a i").toggleClass "closed"
        $(this).find("a i").toggleClass "opened"
        $("#free-shipping-easter-egg").slideToggle 200
        ariaExpand = false

        if ($(this).find("a").attr("aria-expanded") is "false")
            ariaExpand = true

        $(this).find("a").attr("aria-expanded", ariaExpand)
        return false

module.exports = FreeShippingMasthead
