$ = require "jquery"
$$ = require "lib"
Ladda = require "./vendor/ladda/js/ladda.js"
Sku = require "sku_model"
MicrodataParser = require "microdata_parser"
FormResponseHandler = require "ajax_form_response_handler"

unlessBlank = (val)-> if val == "" then undefined else val

skuFromInput = ($input, unitName)->
  sku = new Sku
    externalId: unlessBlank $input.data "external-id"
    unitName: unlessBlank $input.data "unit-name"
    unitPrice: unlessBlank parseFloat($input.data "unit-price")
    packagingType: unlessBlank $input.data "packaging-type"
    weight: unlessBlank parseFloat($input.data "weight")
    wholesale: unlessBlank $input.data "wholesale"
    bulk: unlessBlank $input.data "bulk"
  sku.unitName = unitName if unitName?
  sku.id = $input.val()
  sku

class AddToCartForm
  constructor: (@$elm, @events)->
    return false unless @$elm.length
    @$quantityInput = @$elm.find("#cart_sku-quantity, #mobile-sku-quantity")
    @$totalElm = @$elm.find(".total")
    @multiSkuMode = !!@$elm.find('input[name="cart_sku[id]"][data-variation-skus]').length
    @$elm.submit (e)=>
      e.preventDefault()
      @$mobileSkuSelect = $("#mobile-sku-select")
      if (@$mobileSkuSelect).attr("disabled") == "disabled"
        @$mobileSkuSelect.removeAttr("disabled")
      @_submitForm()
    @_setupLiveTotalCalculation()
    @_setupBulkDiscountUI()
    @_setupBulkDiscountUIForWeb581()
    @_storeAllVariations()
    @_selectVariation()
    @_updateFormData()
    @ladda = Ladda.create @$elm.find("button.primary").get(0)
    @handler = new FormResponseHandler @$elm

  _itemData: =>
    mp = new MicrodataParser(@$elm, "http://schema.org/Product")
    itemData =
      name: mp.property("name")
      id: if @currentSku? then @currentSku.externalId else mp.property("sku")
      itemExternalId: mp.property("itemExternalId")
      quantity: parseInt(@quantity)
      price: if @total then @total else mp.property("price")
      unitName: if @currentSku? then @currentSku.unitName else mp.property("unitName")
    return itemData

  _submitForm: =>
    if message = @_checkForMissingOptions()
      @handler.handleResponse { errors: [ { message: message } ] }
      return false
    formData = @$elm.serialize()
    @_showLoader()
    @events.emit "add_to_cart_form:added_item_to_cart", @_itemData()

    $.ajax(@$elm.attr("action"), type: "POST", data: formData).done((response)=>
      @events.emit "add_to_cart_form:added_cart_line_to_cart", response
      if response.redirect_to && response.redirect_to.url
          window.location = response.redirect_to.url

      @_hideLoader()
      @handler.resetFeedback
    ).fail((xhr, status, error)=>
      console.log error
      @_hideLoader()
      @handler.resetFeedback
      messages = { errors: [ { message: 'Sorry, there was a problem adding this item to your cart.' } ] }
      @handler.handleResponse messages
    )


  _setupLiveTotalCalculation: ->
    me = this
    @$elm.find('input[name="cart_sku[id]"]').on "change click", me._updateFormData
    @$elm.find("select").on "change", me._updateFormData
    @$quantityInput.on("change", me._updateFormData) if @$quantityInput.length

  _setupBulkDiscountUI: ->
    me = this
    @$bulkDiscountLink = @$elm.find(".ptn-bulk-discounts-link")
    @$elm.find('input[name="cart_sku[id]"]').each ->
      $li = $(this).closest("li.sku")
      sku = skuFromInput $(this), $li.find(".sku-name").text().split(" ").slice(-1)[0] # last word
      if sku.hasBulkDiscounts() and $li.find(".bulk-discounts").length
        discountTableView = new DiscountTableView sku, me.events
        $li.find(".bulk-discounts").append discountTableView.render()
        me.$bulkDiscountLink.show()
    @$bulkDiscountLink.click (e)=>
      e.preventDefault()
      toggleText = =>
        if @$bulkDiscountLink.text().indexOf("Hide") == 0
          @$bulkDiscountLink.text "Show bulk discounts"
        else
          @$bulkDiscountLink.text "Hide bulk discounts"
        @events.emit "add_to_cart_form:bulk_discounts_toggled"
      # see http://www.paulirish.com/2008/sequentially-chain-your-callbacks-in-jquery-two-ways/
      (toggleNext = (jq) =>
        jq.eq(0).slideToggle duration: 0, done: =>
          if (jq = jq.slice(1)).length then toggleNext(jq) else toggleText()
      ) @$elm.find(".bulk-discounts")

  _setupBulkDiscountUIForWeb581: ->
    me = this
    @$elm.find('input[name="cart_sku[id]"]').each ->
      $li = $(this).closest("li.sku")
      sku = skuFromInput $(this), $li.find(".sku-name").text().split(" ").slice(-1)[0] # last word
      if sku.hasBulkDiscounts() and $li.find(".bulk-discounts").length
        discountTableViewWeb581 = new DiscountTableViewWeb581 sku, me.events
        $('.bulk-discounts-content').append discountTableViewWeb581.render()

  _updateFormData: (e)=>
    @_updateQuantity()
    @_updateSku()
    @_updateVariations()
    if @currentSku?
      @discount = @currentSku.discount(@quantity)
      @_calculateTotal()
      @events.emit("add_to_cart_form:changed", @currentSku.id, @quantity)
    return true

  _calculateTotal: ->
    subtotal = @currentSku.priceForQuantity @quantity
    @total = (subtotal + @variationPrice) * @quantity
    @$totalElm.html($$.formatMoney(@total)) if @$totalElm.length and @total

  _updateQuantity: ->
    @quantity = 1
    if @$quantityInput.length
      val = @$quantityInput.val()
      if val == "more"
        lastOptionValue = parseInt(@$quantityInput.children("option:nth-last-child(2)").val())
        newVal = if lastOptionValue > 10 then '' else lastOptionValue + 1
        $input = $('<input type="number" name="cart_sku[quantity]" min="1" />')
        @$quantityInput.each ->
          $(this).replaceWith $input
        @$quantityInput = $input.on("keyup change", @_updateFormData.bind(this)).focus().val(newVal)
      else
        @quantity = Math.abs(parseInt(val || 1))

  _updateSku: ->
    @$elm.find(".wholesale-note").hide()
    $input = @$elm.find('input[name="cart_sku[id]"]:checked')
    $input.parent().find(".wholesale-note").show()

    if $input.length then @currentSku = skuFromInput $input
    if $input.data('variation-skus')?
      skuVariationSkus = $input.data('variation-skus').split(',')
      for attrId, variations of @allVariations
        $sel          = @$elm.find('#' + attrId)
        selectedVar   = $sel.children(':checked').val()
        defaultOption = $sel.children(':first')
        attrId        = $sel.attr('id')
        $sel.empty().append(defaultOption)
        ids = []
        for id, variation of variations
          for sku in variation.skus
            if sku in skuVariationSkus
              $sel.append variation.element
              ids.push id
              break
        if ids.length is 1
          $sel.val(ids[0])
        else if selectedVar in ids
          $sel.val(selectedVar)

  _updateVariations: ->
    @variationPrice = 0
    applicableSkus = null
    for attrId, variations of @allVariations
      $sel = @$elm.find('#' + attrId)
      $variation = $sel.find(':selected')
      continue if $variation.length is 0

      match = /\$([\d,.]+)(\/lb)?/.exec $variation.text()
      if match
        variationPrice = match[1] - 0
        variationPrice *= @currentSku.weight if match[2] and @currentSku.weight # variation price is per lb
        @variationPrice += variationPrice

      continue if not $sel.val()
      continue if not @multiSkuMode

      skus = variations[$sel.val()].skus
      if applicableSkus?
        stillApplicableSkus = []
        for sku in skus
          if sku in applicableSkus
            stillApplicableSkus.push sku
        applicableSkus = stillApplicableSkus
      else
        applicableSkus = skus

    if @multiSkuMode and applicableSkus?
      for input in @$elm.find('input[name="cart_sku[id]"]')
        $input = $(input)
        skuVariationSkus = $input.data('variation-skus').split(',')
        for sku in applicableSkus
          if sku in skuVariationSkus
            $input.data('external-id', sku)
            $input.val(sku)
            if $input.prop('checked')
              @currentSku = skuFromInput $input
            break

  _storeAllVariations: ->
    @allVariations = {}
    for sel in @$elm.find('select[name="cart_sku[variations][]"]')
      attrId = $(sel).attr('id')
      @allVariations[attrId] = {}
      if @multiSkuMode
        $(sel).attr('name', '')
        for opt in $(sel).children('option')
          if $(opt).data('sku-external-ids')?
            varId = $(opt).val()
            elemId = $(opt).attr('id')
            varName = $(opt).text()
            @allVariations[attrId][varId] =
              element: '<option id="'+elemId+'" value="'+varId+'">'+varName+'</option>'
              skus:    $(opt).data('sku-external-ids').split(',')

  _selectVariation: ->
    unless @$elm.parent().hasClass "completely-backordered"
      if @$elm.find('input[name="cart_sku[id]"]:checked').length is 0
        @$elm.find('input[name="cart_sku[id]"]:not(:disabled):first').prop('checked', true) # selects the first non-disabled SKU
      if @$elm.find('option:selected').length is 0
        @$elm.find('option:first').prop('selected', true)

  _checkForMissingOptions: ->
    missing_options = []
    message = null
    for sel in @$elm.find('.attribute > select')
      if not $(sel).val()
        option_name = $(sel).attr('id').split('_')
        missing_options.push ' a ' + option_name[1].replace(/([A-Z])/g, ' $1').trim() + ' option'
    if missing_options.length > 0
      message = 'Please select' + missing_options.join(' and') + '.'
    return message

  _showLoader: -> @ladda.start() if @ladda
  _hideLoader: -> @ladda.stop() if @ladda

# DOM stuff for the discount table. Creates its own HTML.
# A little bit unwieldy but self-contained.
class DiscountTableView
  constructor: (@sku, @events)->
    me = this
    @_createTable()
  render: -> @table
  _createTable: ->
    if @sku.wholesale or !@sku.weight
      @table = null
    else
      @table = $ "<table><tr><th>Quantity</th><th>Price per #{@sku.unitName}</th></tr></table>"
      @rows = []
      if @sku.weight is 1
        for range in [[1, 5], [6, 10], [11]]
          @rows.push @_tableRow range[0], range[1]
      else
        for range in [[1, 1], [2, 2], [3]]
          @rows.push @_tableRow range[0], range[1]
      @table.append @rows
    return @table
  _tableRow: (start, end)->
    return $("<tr>
      <td>#{@_rangeString(start, end)}</td>
      <td>$#{$$.formatMoney @sku.priceForQuantity(start)}
      <span>($#{$$.formatMoney @sku.pricePerPoundForQuantity(start)}/lb)</span></td>
      </tr>").data start: start, end: end
  _rangeString: (start, end)->
    if end
      if start is end then start else "#{start} - #{end}"
    else
      return "#{start} +"

class DiscountTableViewWeb581
  constructor: (@sku, @events)->
    me = this
    @_createTable()
  render: -> @table
  _createTable: ->
    if @sku.wholesale or !@sku.weight
      @table = null
    else
      @table = $ "<table></table>"
      @thead = $ "<thead><tr><th><span class='bulk-discount-header'>Quantity</span></th><th><span class='bulk-discount-header'>Price per #{@sku.packagingType}</span></th></tr></thead>"
      @tbody = $ "<tbody></tbody>"
      @caption = $ "<caption>$#{$$.formatMoney @sku.unitPrice} / #{@sku.weight}lb #{@sku.unitName}<span class='bulk-discount-sub-copy'> ($#{$$.formatMoney @sku.pricePerPoundForQuantity(1)}/lb)</span></caption>"
      @table.append @caption
      @table.append @thead
      @rows = []
      if @sku.weight is 1
        for range in [[1, 5], [6, 10], [11]]
          @rows.push @_tableRow range[0], range[1]
      else
        for range in [[1, 1], [2, 2], [3]]
          @rows.push @_tableRow range[0], range[1]
      @tbody.append @rows
      @table.append @tbody
    return @table
  _tableRow: (start, end)->
    return $("<tr>
      <td><span class='bulk-discount-copy'>#{@_rangeString(start, end)}</span></td>
      <td><span class='bulk-discount-copy'>$#{$$.formatMoney @sku.priceForQuantity(start)}/#{@sku.packagingType}</span>
      <span class='bulk-discount-sub-copy'> ($#{$$.formatMoney @sku.pricePerPoundForQuantity(start)}/lb)</span></td>
      </tr>").data start: start, end: end
  _rangeString: (start, end)->
    if end
      if start is end then start else "#{start} - #{end}"
    else
      return "#{start} +"

module.exports = AddToCartForm
