$ = require 'jquery'
MicrodataParser = require "microdata_parser"

class ItemClickTracker
  constructor: (@$elm, @events)->
    @$elm.find("a").click =>
      mp = new MicrodataParser(@$elm, "http://schema.org/Product")
      item =
        id: mp.property("sku")
        name: mp.property("name")
        position: @$elm.data("index")
        # price: mp.property("price") # do we care?
        list: @$elm.data("list")
      @events.emit("item_click_tracker:click", item)

module.exports = ItemClickTracker
