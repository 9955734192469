$ = require "jquery"

class TapEvents
  constructor: ->
    incrementalElementId = 0
    mutex = 0
    $.fn.tap = (threshold, callback) ->
      if typeof threshold is "function"
        callback = threshold
        threshold = 15
      if "ontouchstart" of window
        @each ->
          moveDistance = 0
          touch = null
          elementId = ++incrementalElementId
          startPoint = null
          touching = false
          self = this
          $self = $(this)
          $self.bind "touchstart", (e) ->
            unless mutex is 0
              return
            else
              mutex = elementId
            touching = true
            moveDistance = 0
            if e.originalEvent.touches and e.originalEvent.touches[0]
              touch = e.originalEvent.touches[0]
              startPoint =
                x: touch.screenX
                y: touch.screenY
            return

          $self.bind "touchend", (e) ->
            console.log "touchend"
            mutex = 0  if mutex is elementId
            return  unless touching
            touching = false
            if moveDistance < threshold
              callback.apply self, [].slice.call(arguments)
            else
              $self.trigger "tap-failed"
            return

          $self.bind "touchmove", (e) ->
            return  unless touching
            return touching = false  if e.originalEvent.touches.length is 0 or startPoint is null
            touch = e.originalEvent.touches[0]
            moveDistance = Math.sqrt(Math.pow(touch.screenX - startPoint.x, 2) + Math.pow(touch.screenY - startPoint.y, 2))
            if moveDistance > threshold
              $self.trigger "exceed-tap-threshold"
              touching = false
            return

          $self.bind "touchcancel", ->
            mutex = 0  if mutex is elementId
            touching = false
            $self.trigger "tap-failed"
            return
          return

      this
    return

module.exports = TapEvents
