# putting a require here puts a second entire copy of jquery
# into the build. TODO solve this better to allow declaring
# the dependency here and letting browserify figure out how
# and when to include it. with the current set-up, though,
# jquery is still usable as a global var on window.
#
# $ = require "jquery"
Mailcheck = require "./vendor/mailcheck/src/mailcheck.js"
EventEmitter = require "event_emitter"

class NutsMailcheck extends EventEmitter
  constructor: ->
    super()
    Mailcheck.defaultDomains = Mailcheck.defaultDomains.concat [
      "bellsouth.net"
      "charter.net"
      "cox.net"
      "earthlink.net"
      "optonline.net"
      "yahoo.ca"
    ]

  check: (value)->
    me = this
    Mailcheck.run
      email: value
      suggested: (suggestion)->
        suggestion.full = suggestion.full.replace(/%20/g, "")
        me.emit "suggestion", suggestion.full
      empty: ->
        originalValue = value
        value = value.replace(/\s/g, "") if value.indexOf(" ") >= 0
        value = me._tryToInsertAtSymbol(value) if value.indexOf("@") < 0
        if value and value != originalValue
          me.emit("suggestion", value)
        else
          me.emit("empty")

  _tryToInsertAtSymbol: (typo)->
    for domain in Mailcheck.defaultDomains
      re = new RegExp(domain + "$")
      if (matchIndex = typo.search(re)) > 0
        if typo.charAt(matchIndex - 1) is 2
          return typo.substr(0, matchIndex - 1) + "@" + typo.substr(matchIndex)
        else
          return typo.replace(domain, "@" + domain)
        break

module.exports = NutsMailcheck
