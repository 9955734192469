$ = require "jquery"

class SocialSharing
  constructor: (@$elm, @events) ->
    @$elm.find("a").click (e) ->
      if screen.width > 1024 or !('ontouchstart' of window)
        url = $(this).attr('href')
        windowname = $(this).attr('title')
        w = if $(this).data("share-width")  then $(this).data("share-width")  else 640
        h = if $(this).data("share-height") then $(this).data("share-height") else 400
        e.preventDefault()
        window.open(url, windowname, "toolbar=no, menubar=no, status=no, directories=no, width=" + w + ", height=" + h)

module.exports = SocialSharing
