# Adapted from https://github.com/desandro/imagesloaded/blob/master/imagesloaded.js
# (The original is too bloated!)

$ = require "jquery"
EventEmitter = require "event_emitter"

((window, factory) ->
  "use strict"
  module.exports = factory(window, EventEmitter)
  return
) window, factory = (window, EventEmitter) ->

  cache = {}

  class LoadingImage extends EventEmitter
    constructor: (@img)->
      super()
    check: ->
      resource = cache[@img.src] or new Resource(@img.src)
      if resource.isConfirmed
        @confirm resource.isLoaded, "cached was confirmed"
        return
      if @img.complete and @img.naturalWidth isnt `undefined`
        @confirm @img.naturalWidth isnt 0, "naturalWidth"
        return
      me = this
      resource.on "confirm", (resrc, message) ->
        me.confirm resrc.isLoaded, message
        true
      resource.check()
      return
    confirm: (isLoaded, message) ->
      @isLoaded = isLoaded
      @emit "confirm", this, message
      return

  class Resource extends EventEmitter
    constructor: (@src)->
      super
    check: ->
      return if @isChecked
      # simulate loading on detached element
      proxyImage = new Image()
      $(proxyImage).on "load", @onload.bind(this)
      $(proxyImage).on "error", @onerror.bind(this)
      proxyImage.src = @src
      @isChecked = true
      return
    onload: (event) ->
      @confirm true, "onload"
      @unbindProxyEvents event
      return
    onerror: (event) ->
      @confirm false, "onerror"
      @unbindProxyEvents event
      return
    confirm: (isLoaded, message) ->
      @isConfirmed = true
      @isLoaded = isLoaded
      @emit "confirm", this, message
      return
    unbindProxyEvents: (event) ->
      $(event.target).off "load", @onload.bind(this)
      $(event.target).off "error", @onerror.bind(this)
      return

  LoadingImage
