$ = require "jquery"
$$ = require "lib"
EventEmitter = require "event_emitter"

class AjaxFormResponseHandler extends EventEmitter
  constructor: (@$elm, options)->
    @feedbackElements = []
    @feedback = {}
    @options = automaticallyShowFeedback: true
    $$.extend @options, options
    super()

  handleResponse: (response)->
    @resetFeedback()
    @feedback = response
    if @feedback && @options.automaticallyShowFeedback then @showFeedback()
    if @feedback then @_emitEvents()

  showFeedback: ->
    if @feedback.errors
      @_applyFeedback('error', item) for item in @feedback.errors
    else if @feedback.notices
      @_applyFeedback('notice', item) for item in @feedback.notices
      @$elm.hide()

  _emitEvents: ->
    if @feedback.errors
      @emit("error", @feedback.errors)
    else if @feedback.notices
      @emit("success", @feedback.notices)
    else
      @emit "success"

  _applyFeedback: (type, feedbackData)->
    if feedbackData.field
      $item = $("<span class='message'>#{feedbackData.message}</div>")
      @$elm
        .find("input[name=\"#{feedbackData.field}\"]")
        .closest(".form-group")
        .addClass("has-#{type}")
        .addClass("has-#{type}-message")
        .append $item
    else
      $item = $("<div class='#{type} alert'>#{feedbackData.message}</div>")
      $item.insertBefore @$elm
    @feedbackElements.push $item

  resetFeedback: ->
    $item.remove() for $item in @feedbackElements
    @feedbackElements = []

module.exports = AjaxFormResponseHandler
