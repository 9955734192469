$ = require "jquery"
$$ = require "lib"
FormResponseHandler = require "ajax_form_response_handler"

class AjaxForm
  constructor: (@$elm, events)->
    url = @$elm.attr "action"
    method = @$elm.attr "method"
    handler = new FormResponseHandler @$elm
    @$elm.submit (e)->
      e.preventDefault()
      $.ajax(
        url: url
        type: method
        data: $(this).serialize()
      ).done (response)->
        handler.handleResponse response
      .fail (jqXHR, textStatus, errorThrown)->
          console.error("Ajax request failed. Status:", textStatus, "Error:", errorThrown)

module.exports = AjaxForm
