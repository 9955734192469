# putting a require here puts a second entire copy of jquery
# into the build. TODO solve this better to allow declaring
# the dependency here and letting browserify figure out how
# and when to include it. with the current set-up, though,
# jquery is still usable as a global var on window.
#
# $ = require "jquery"
$$ = require 'lib'
NutsMailcheck = require "nuts_mailcheck"
mailcheckers = {};

class Subscribe
  constructor: (@$elm, @$form, @events)->
    $emailField = @$elm
    id = $emailField.attr('id')
    mailcheckers[id] = new NutsMailcheck()

    $subscribeForm = @$form
    isSuggestionVisible = false
    enterKeyPressCount = 0

    that = this
    $subscribeForm.on "keypress", (e)->
      if e.keyCode == 13
        enterKeyPressCount++
        if isSuggestionVisible and enterKeyPressCount < 2
          e.preventDefault()
          that.highlightSuggestionBox($subscribeForm.find("a[class='suggestion']"))

    timeoutHandle = null
    $emailField.on "keyup", (e)->
      if e.keyCode != 13
        clearTimeout(timeoutHandle)
        timeoutHandle = setTimeout(that.checkEmail, 250, $emailField, mailcheckers[id])

    mailcheckers[id].on "empty", ->
      $emailField.closest('.form-group').find('.suggestion').remove()
      isSuggestionVisible = false
    mailcheckers[id].on "suggestion", (suggestion)->
      $suggested = $("<a class=\"suggestion\">Did you mean #{suggestion}?</a>")
      $emailField.closest('.form-group').find('.suggestion').remove()
      $emailField.after($suggested)
      $suggested.click ->
        $(this).remove()
        $emailField.val suggestion
        $emailField.next('.first-name-hidden').slideDown('slow') if $$.validEmail($emailField.val())
        isSuggestionVisible = false
      isSuggestionVisible = true
    mailcheckers[id].check $emailField.val()

    $emailField.bind 'input propertychange', ->
      email = $(this).val()
      $(this).next('.first-name-hidden').slideDown('slow') if $$.validEmail(email)

  checkEmail: ($emailField, mailchecker)->
    mailchecker.check $emailField.val()

  highlightSuggestionBox: ($suggestionBox)->
    $suggestionBox.animate({
      opacity: 0.4
    }, 250)
    $suggestionBox.animate({
      opacity: 1.0
    }, 250)
    $suggestionBox.animate({
      opacity: 0.4
    }, 250)
    $suggestionBox.animate({
      opacity: 1.0
    }, 250)

module.exports = Subscribe
