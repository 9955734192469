$ = require "jquery"

EventEmitter = require "event_emitter"
events = new EventEmitter()

AjaxForm = require "ajax_form"
$(".ajax-form").each -> new AjaxForm($(this), events)
Collapse = require "collapse"
$('.coffee-collapse').each -> new Collapse($(this), events)
ExpandableText = require "expandable_text"
$(".ptn-expandable-text").each -> new ExpandableText($(this), events)
RuledRows = require "ruled_rows"
$(".ptn-ruled-rows").each -> new RuledRows($(this), events)
Autofocuser = require "autofocuser"
$(".ptn-autofocuser").each -> new Autofocuser($(this), events)

AddToCartForm = require "add_to_cart_form"
$(".add-to-cart").each -> new AddToCartForm($(this), events)

SocialSharing = require "social_sharing"
new SocialSharing $(".socialcount"), events

# instantiate these last, components above may listen for their events
ItemImpressionTracker = require "item_impression_tracker"
new ItemImpressionTracker events
ItemClickTracker = require "item_click_tracker"
$(".click-tracker").each -> new ItemClickTracker($(this), events)
PageviewCounter = require "pageview_counter"
new PageviewCounter events

Analytics = require "analytics"
new Analytics events
Subscribe = require "subscribe"
$('#inputEmailFooter, #inputEmailSidebar, #shipment-email_address').each -> new Subscribe($(this), $(this).closest('form'), events)
