class MicrodataParser
  constructor: ($elm, itemtype)->
    @itemscope = $elm.closest("[itemscope][itemtype=\"#{itemtype}\"]")

  property: (path...)->
    selector = ("[itemprop=\"#{name}\"]" for name in path).join " "
    $prop = @itemscope.find(selector).first()
    $prop.attr("content") or $.trim($prop.text())

module.exports = MicrodataParser
