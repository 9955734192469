$ = require "jquery"

class LoadingIndicator
  constructor: (@fallbackMessage="Loading...")->
    @showing = false

  show: ($target)->
    if !@showing
      @_create()
      $target.append @$elm
      @$elm.show()
      @showing = true

  hide: ($target)->
    $target.get(0).removeChild(@$elm.get(0)) if @$elm and @$elm.length
    @showing = false

  _create: ->
    @$elm = $("<div class=\"ptn-loading-indicator\"></div>")
    @$elm.addClass "fancy"
    for i in [1..5]
      rect = $("<div class=\"rect#{i}\"></div>")
      @$elm.append rect

module.exports = LoadingIndicator
