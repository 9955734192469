module.exports =

  # from http://www.quirksmode.org/js/cookies.html
  readCookie: (name) ->
    nameEQ = name + "="
    ca = document.cookie.split(";")
    i = 0
    while i < ca.length
      c = ca[i]
      c = c.substring(1, c.length)  while c.charAt(0) is " "
      if c.indexOf(nameEQ) is 0
        return decodeURIComponent(c.substring(nameEQ.length, c.length))
      i++
    null

  createCookie: (name, value, days) ->
    if days
      date = new Date()
      date.setTime date.getTime() + (days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toGMTString()
    else
      expires = ""
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/"
    return

  formatMoney: (float)->
    float.toFixed(2).replace /./g, (c, i, a) ->
      (if i and c isnt "." and not ((a.length - i) % 3) then "," + c else c)

  roundPrecision: (number, precision=2) ->
    number *= Math.pow(10, precision)
    number = Math.round(number)
    number /= Math.pow(10, precision)
    number

  extend: (destination, source) ->
    for property of source
      destination[property] = source[property] if source.hasOwnProperty(property)
    destination

  validEmail: (email) ->
    re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    return re.test email

  qs: (key) ->
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&')
    match = location.search.match(new RegExp('[?&]' + key + '=([^&]+)(&|$)'))
    match and decodeURIComponent(match[1].replace(/\+/g, ' '))
