$ = require "jquery"

# This component handles only the *showing* of text and not the *hiding*.
# You'll need to use css to set display:none on children elements that are
# to be initially hidden. Give the @$elm a data attr 'truncate' with an int
# value and hide the child .remainder element for a truncated single paragraph.

class ExpandableText
  constructor: (@$elm, @events)->
    return false unless @$elm.length
    me = this

    if truncateLength = @$elm.data("truncate")
      fullString = @$elm.text() + " "
      if fullString.length > truncateLength and fullString.length > 1
        cutPoint = fullString.substr(0, truncateLength).lastIndexOf " " # cut back to last word
        @$elm.html($(
          "<span class='truncated'>#{fullString.substr(0, cutPoint)}</span>" +
          "<span class='ellipsis'>...</span>" +
          "<span class='remainder'>#{fullString.substr(cutPoint)}</span>"
        ))
        @$elm.after @_generateReadMoreLink()

    else
      @$elm.find("p").first().after me._generateReadMoreLink()

  _generateReadMoreLink: ->
    $link = $("<a href=\"#\" class=\"ptn-read-more-link\">Read More</a>")
    $link.click (e)=>
      e.preventDefault()
      @$elm.find("*").show().find(".ellipsis").hide()
      $link.hide()

module.exports = ExpandableText
