$ = require "jquery"

class FreeShippingBanner
  constructor: (@$elm)->
    @open = false
    @$details = @$elm.find ".details"
    @$elm.click @_toggle

  _toggle: =>
    if @open then @_close() else @_open()

  _open: =>
    @$elm.removeClass("closed").addClass("open")
    @$details.fadeIn()
    @open = true

  _close: =>
    @$elm.removeClass("open").addClass("closed")
    @$details.fadeOut()
    @open = false

module.exports = FreeShippingBanner
