$$ = require 'lib'

class PageviewCounter
  constructor: (@events)->
    unless $$.readCookie 'pageviewCount'
      $$.createCookie 'pageviewCount', 0, 365
    count = parseInt($$.readCookie 'pageviewCount')
    $$.createCookie 'pageviewCount', count+1, 365
    @events.emit "pageview", count+1

module.exports = PageviewCounter
