$ = require "jquery"

isInViewport = (element, options) ->
  boundingRect = element.getBoundingClientRect()
  top = boundingRect.top
  bottom = boundingRect.bottom
  left = boundingRect.left
  right = boundingRect.right
  settings = $.extend(
    tolerance: 0
  , options)
  isVisibleFlag = false
  $viewport = $(window)
  $viewportHeight = $viewport.height()
  $viewportWidth = $viewport.width()

  settings.tolerance = $viewportHeight + settings.tolerance  if settings.tolerance < 0

  #if the element is laterally outside the viewport
  return false if Math.abs(left) >= $viewportWidth

  isVisibleFlag = if settings.tolerance
      !!(top <= settings.tolerance and bottom >= settings.tolerance)
    else
      !!(bottom > 0 and top <= $viewportHeight)

  return isVisibleFlag

module.exports = isInViewport
