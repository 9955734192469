$ = require "jquery"

class RuledRows
  constructor: (@$elm, @events)->
    return false unless @$elm.length
    return false unless @$elm.get(0).children[0]
    me = this
    @columns = xs: 1, sm: null, md: null, lg: null

    # determine the column settings from the first child
    for className in @$elm.get(0).children[0].className.split " "
      if className.indexOf("col-") > -1
        parts = className.split "-"
        @columns[parts[1]] = 12 / parseInt(parts[2])

    # handle cascade for breakpoints which aren't defined
    columnSizeKeys = ['xs', 'sm', 'md', 'lg']
    for key, i in columnSizeKeys
      @columns[key] = @columns[columnSizeKeys[i-1]] if @columns[key] == null

    @$elm.children().each (j, elm)->
      me._insertDividersBeforeElm(elm, j) if j != 0

  _insertDividersBeforeElm: (elm, index)->
    for breakpoint, columnCount of @columns
      if index % columnCount == 0
        divider = document.createElement "div"
        divider.className = "rule clearfix visible-#{breakpoint}"
        @$elm.get(0).insertBefore divider, elm

module.exports = RuledRows
