$$ = require "lib"

class Sku
  constructor: (data)->
    @externalId = data.externalId
    @unitName = data.unitName
    @packagingType = data.packagingType
    @unitPrice = data.unitPrice
    @weight = data.weight
    @wholesale = data.wholesale
    @bulk = data.bulk
    @variationPrice = data.unitVariationPrice

  hasBulkDiscounts: -> @bulk && !@wholesale

  priceForQuantity: (quantity)->
    if @hasBulkDiscounts()
      subtotal = $$.roundPrecision @pricePerPoundForQuantity(quantity)
      return $$.roundPrecision(subtotal * @weight)
    else
      @unitPrice = 0 if !@unitPrice
      @variationPrice = 0 if !@variationPrice
      return $$.roundPrecision(+@unitPrice + +@variationPrice)

  pricePerPoundForQuantity: (quantity)->
    price = (@unitPrice / @weight) * (1 - @discount(quantity))
    if @variationPrice? then price += @variationPrice / @weight
    price

  discount: (quantity)->
    if @wholesale
      return 0
    else
      if @weight is 1
        if quantity <= 5
          return 0.00
        else if quantity <= 10
          return 0.03
        else
          return 0.05
      else
        if quantity <= 1
          return 0.03
        else if quantity <= 2
          return 0.05
        else
          return 0.10

module.exports = Sku
