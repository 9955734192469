$ = require 'jquery'
isinviewport = require "is_in_viewport"
MicrodataParser = require "microdata_parser"
require "throttle" # for $.debounce()

class ItemImpressionTracker
  constructor: (@events)->
    $(window).scroll $.debounce 300, @_trackVisibleItems
    $(window).on "load", @_trackVisibleItems

  _trackVisibleItems: =>
    items = []
    $('.impression-tracker').each ->
      if isinviewport(this)
        $this = $(this)
        mp = new MicrodataParser($this, "http://schema.org/Product")
        items.push
          id: mp.property("sku")
          name: mp.property("name")
          position: $this.data("index")
          # price: mp.property("price") # do we care?
          list: $this.data("list")
        $this.removeClass "impression-tracker"
    @events.emit("item_impression_tracker:impressions", items) if items.length

module.exports = ItemImpressionTracker
