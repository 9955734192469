$ = require 'jquery'

class Analytics
  constructor: (@events)->
    @events.on "add_to_cart_form:added_item_to_cart", (itemData)=>
      dataLayer.push({
        'event': 'addToCart',
        'location': @_getPageName(),
        'ecommerce': {
          'currencyCode': 'USD',
          'add': {
            'products': @_addListToItemData([itemData])
          }
        }
      })

    @events.on "cart_table_row:removed", (itemData)=>
      dataLayer.push({
        'event': 'removeFromCart',
        'location': @_getPageName(),
        'ecommerce': {
          'remove': {
            'products': @_addListToItemData([itemData])
          }
        }
      })

    @events.on "item_impression_tracker:impressions", (itemsData=[])=>
      dataLayer.push({
        'event': 'productImpressions',
        'location': @_getPageName(),
        'ecommerce': {
          'currencyCode': 'USD',
          'impressions': @_addListToItemData(itemsData)
        }
      })

    @events.on "item_click_tracker:click", (itemData)=>
      dataLayer.push({
        'event': 'productClick',
        'location': @_getPageName(),
        'ecommerce': {
          'click': {
            'actionFields': {
              'list': (itemData.list || @_getPageName())
            },
            'products': @_addListToItemData([itemData])
          }
        }
      })

  _addListToItemData: (itemData)->
    for product in itemData
      product.list ?= @_getPageName()
    itemData

  _getPageName: ->
    unless @pageName
      for className in $("body").attr("class").split(" ")
        if className.indexOf("page-") == 0 then @pageName = className[5..]
    @pageName

module.exports = Analytics
